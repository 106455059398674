import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'
import color from 'color'

import { responseStylesToCSS, Styles } from './helpers'

const colorPrimary = '#e06700'

export const fontFamily = {
	primary: '"Amatic SC", "Trebuchet MS", serif',
	secondary: '"Josefin Sans", "Trebuchet MS", serif'
}

// Strip any fallback fonts during development so it is very obvious if fonts are not loading properly
if (process.env.GATSBY_ACTIVE_ENV === 'development') {
	fontFamily.primary = fontFamily.primary.replace(/,.*$/, ', monospace')
	fontFamily.secondary = fontFamily.secondary.replace(/,.*$/, ', monospace')
}

// Tell Material-UI what's the font-size on the html element .
// 16px is the default font-size used by browsers.
const htmlFontSize = 16
const pxToRem = (px: number) => `${px / htmlFontSize}rem`

/**
 * Tweak the default MUI settings here
 * @see: https://material-ui.com/customization/default-theme/
 */
export const overridings: ThemeOptions = {
	palette: {
		primary: {
			main: colorPrimary,
			contrastText: '#fff'
		},
		secondary: {
			main: '#333',
			contrastText: '#fff'
		}
	},
	typography: {
		fontFamily: fontFamily.secondary,
		h1: {
			fontFamily: fontFamily.primary,
			fontWeight: 600,
			fontSize: pxToRem(45)
		},
		h2: {
			fontFamily: fontFamily.primary,
			fontWeight: 600,
			fontSize: pxToRem(33)
		},
		h3: {
			fontFamily: fontFamily.primary,
			fontWeight: 600,
			fontSize: pxToRem(30)
		},
		h4: {
			fontFamily: fontFamily.primary,
			fontWeight: 600,
			fontSize: pxToRem(25)
		},
		h5: {
			fontFamily: fontFamily.primary,
			fontWeight: 600
		},
		h6: {
			fontFamily: fontFamily.primary,
			fontWeight: 600
		},
		body1: {
			fontSize: pxToRem(18),
			fontWeight: 400
		},
		button: {
			fontFamily: fontFamily.primary,
			fontWeight: 600,
			fontSize: pxToRem(27)
		}
	}
}

const muiTheme = createMuiTheme(overridings)

export default muiTheme

const themeBreakpoints: string[] = muiTheme.breakpoints.keys.map((key) => `${muiTheme.breakpoints.values[key]}px`)

export const themeResponseStylesToCSS = (styles: Styles) => responseStylesToCSS(styles, themeBreakpoints)

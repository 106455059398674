import React, { FunctionComponent } from 'react'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import { default as MaterialLink, LinkProps as MaterialLinkProps } from '@material-ui/core/Link'
import styled, { css, CSSObject } from 'styled-components'

import muiTheme from '~/styles/mui-theme'

export const LinkStyleObject = {
	...muiTheme.typography.h6,
	color: muiTheme.palette.primary.main,
	'&:hover': {
		textDecoration: 'none',
		color: muiTheme.palette.primary.dark
	}
}

type Props = GatsbyLinkProps & MaterialLinkProps

const StyledGatsbyLink = styled(GatsbyLink)(LinkStyleObject as CSSObject)
const StyledLink = styled(MaterialLink)(LinkStyleObject as CSSObject)

// eslint-disable-next-line react/jsx-props-no-spreading
const Link: FunctionComponent<Props> = ({ to, ...rest }: Props) => (to ? <StyledGatsbyLink to={to} {...rest} /> : <StyledLink {...rest} />)

export default Link

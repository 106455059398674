import React, { FunctionComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import { useInView } from 'react-intersection-observer'

import GatsbyBackgroundImage, { FluidObject } from '~/components/GatsbyBackgroundImage'
import Button from '~/components/Button'
import Logo from '~/components/Logo'
import Menu, { MENU_HEIGHT } from '~/components/Menu'
import { themeResponseStylesToCSS } from '~/styles/mui-theme'

interface HeaderProps {
	pathname: string
	locale: string
}

interface DataProps {
	data: {
		contentfulImage: {
			image: {
				localFile: {
					childImageSharp: {
						mobile: FluidObject
						desktop: FluidObject
					}
				}
			}
		}
	}
}

const StyledHeader = styled.header`
	position: relative;
	.header-sticky {
		position: fixed;
		top: 0;
		left: 0;
		bottom: auto;
	}
`

const StyledBackgroundImage = styled(GatsbyBackgroundImage)`
	min-height: 70vh;
	max-height: 800px;
	min-height: 500px;

	display: flex;
	flex-direction: column;
	justify-content: center;
`

const StyledLogo = styled(Logo)`
	position: relative;
	${themeResponseStylesToCSS({ paddingBottom: ['20px', '0px'] })}
`
const StyledMenuPlaceholder = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	height: ${MENU_HEIGHT}px;
	width: 100px; /* does not work with 100% width, probably due to overflow-x setting on body */
	z-index: 2;
`
const StyledMenuWrapper = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100vw;
	z-index: 99;
`

const Header: FunctionComponent<HeaderProps & DataProps> = ({ pathname, locale, data: { contentfulImage } }) => {
	const [ref, inView, entry] = useInView({
		threshold: 1
	})

	const clientRectTop = entry ? entry.boundingClientRect.top : 1
	const isSticky = !inView && clientRectTop <= 0
	const isEnglish = locale === 'en'

	return (
		<StyledHeader>
			<StyledBackgroundImage fluid={contentfulImage.image.localFile.childImageSharp} objectPosition="center 85%">
				<Container
					css={css`
						text-align: center;
						padding-bottom: 120px;
					`}
				>
					<Grid
						container
						alignItems="center"
						css={css`
							text-align: center;
							padding-bottom: 120px;
						`}
					>
						<Grid item sm={3} implementation="css" xsDown component={Hidden} />
						<Grid item xs={12} sm={6}>
							<StyledLogo />
						</Grid>
						<Grid item xs={12} sm={3}>
							<Button href={`mailto:snailhousecampervans@gmail.com?subject=${isEnglish ? 'booking' : 'boeken'}`}>
								{isEnglish ? 'Book now' : 'Nu Boeken'}
							</Button>
						</Grid>
					</Grid>
				</Container>
			</StyledBackgroundImage>

			<StyledMenuPlaceholder ref={ref} />
			<StyledMenuWrapper className={isSticky ? 'header-sticky' : ''}>
				<Menu locale={locale} pathname={pathname} />
			</StyledMenuWrapper>
		</StyledHeader>
	)
}

export default (props: HeaderProps) => (
	<StaticQuery
		query={graphql`
			query HeaderImage {
				contentfulImage(contentfulid: { eq: "header" }) {
					image {
						localFile {
							childImageSharp {
								mobile: fluid(
									quality: 80
									maxWidth: 768
									maxHeight: 768
									srcSetBreakpoints: [500, 768]
									cropFocus: SOUTH
									fit: COVER
									jpegProgressive: false
								) {
									...GatsbyImageSharpFluid_withWebp
								}
								desktop: fluid(quality: 80, maxWidth: 1980, srcSetBreakpoints: [768, 1280, 1366], jpegProgressive: false) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		`}
		// eslint-disable-next-line react/jsx-props-no-spreading
		render={(data) => <Header data={data} {...props} />}
	/>
)

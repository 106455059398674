import React, { FunctionComponent, useState } from 'react'
import styled, { css } from 'styled-components'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'gatsby'
import muiTheme from '~/styles/mui-theme'

export const MENU_HEIGHT = 53

const MOBILE_MENU_THRESHOLD = 400

const StyledWrapper = styled.div`
	position: relative;
	height: ${MENU_HEIGHT}px;
	width: 100%;

	&:before {
		content: '';
		background-color: rgba(0, 0, 0, 0.8);
		width: 200vw;
		height: ${MENU_HEIGHT}px;
		display: block;
		position: absolute;
		top: 0;
		left: -50vw;
		z-index: -1;
		transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
	}

	&.is-open:before {
		height: 100vh;
	}

	@media (min-width: ${MOBILE_MENU_THRESHOLD}px) {
		&.is-open:before {
			height: ${MENU_HEIGHT}px;
		}
	}
`

const StyledContainer = styled(Container)`
	position: absolute;
	top: 0;
	padding-top: 5px;
	padding-bottom: 5px;
	z-index: 10;
	display: flex;
	justify-content: space-between;
`

const StyledMenuLink = styled(Link)`
	font-family: ${muiTheme.typography.button.fontFamily};
	font-weight: ${muiTheme.typography.button.fontWeight};
	font-size: ${muiTheme.typography.button.fontSize};
	color: #fff;
	margin: 10px 0;
	text-decoration: none !important;

	&.active {
		border-bottom: 1px solid white;
		&:before,
		&:after {
			content: '-';
		}
	}
`

const StyledLocaleLink = styled(StyledMenuLink)`
	font-size: ${muiTheme.typography.h6.fontSize};
	position: relative;
	top: -6px;
`

const StyledList = styled.ul`
	height: 100vh;
	width: 100%;
	/* display: none; */
	visibility: hidden;
	padding: 0;
	margin: 0;
	position: absolute;
	top: ${MENU_HEIGHT}px;
	left: 0;
	opacity: 0;

	transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
	transition-property: opacity, visibility;

	.is-open & {
		display: block;
		opacity: 1;
		visibility: visible;
	}

	@media (min-width: ${MOBILE_MENU_THRESHOLD}px) {
		&,
		.is-open & {
			order: -1;
			width: auto;
			height: auto;
			position: relative;
			top: 0;
			opacity: 1;
			visibility: visible;
		}
	}
`
const StyledIconButton = styled(IconButton)`
	@media (min-width: ${MOBILE_MENU_THRESHOLD}px) {
		display: none;
	}
`

const StyledListItem = styled.li`
	display: block;
	text-align: center;
	margin-bottom: 16px;

	@media (min-width: ${MOBILE_MENU_THRESHOLD}px) {
		display: inline-block;
		margin-bottom: 0;

		& + & {
			margin-left: 30px;
		}
	}
`

interface Props {
	className?: string
	locale: string
	pathname: string
}

interface MenuItem {
	url: string
	label: string
}

const menuContent: MenuItem[] = [
	{ url: '/', label: 'Home' },
	{ url: '/campervans/', label: 'Campervans' },
	{ url: '/faq/', label: 'FAQ' }
]

const Menu: FunctionComponent<Props> = ({ className, locale, pathname }) => {
	const [open, setOpen] = useState(false)

	const isDutch = locale === 'nl'
	const localePrefix = isDutch ? '' : '/en'

	return (
		<StyledWrapper className={open ? `is-open ${className || ''}` : className || ''}>
			<StyledContainer>
				<StyledIconButton
					aria-label={open ? 'Close menu' : 'Open menu'}
					onClick={() => setOpen(!open)}
					css={css`
						position: relative;
						top: -6px;
					`}
				>
					{open ? <CloseIcon style={{ color: '#FFF' }} /> : <MenuIcon style={{ color: '#FFF' }} />}
				</StyledIconButton>

				<StyledLocaleLink to={`${isDutch ? `/en${pathname}` : pathname.replace(/^\/en/, '')}`}>
					{isDutch ? 'English' : 'Nederlands'}
				</StyledLocaleLink>

				<StyledList role="navigation" aria-label="Main menu">
					{menuContent.map(({ url, label }) => (
						<StyledListItem key={url}>
							<StyledMenuLink to={`${localePrefix}${url}`} activeClassName="active">
								{label}
							</StyledMenuLink>
						</StyledListItem>
					))}
				</StyledList>
			</StyledContainer>
		</StyledWrapper>
	)
}

export default Menu

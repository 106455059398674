import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { PageRendererProps } from 'gatsby'

type HeadProps = {
	lang: string
	title: string
	description: string
	keywords?: string[]
	siteUrl: string
} & PageRendererProps

const Head: FunctionComponent<HeadProps> = ({ title, description, keywords, siteUrl, location, lang }) => (
	<>
		<Helmet
			htmlAttributes={{
				lang
			}}
		>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content={(keywords || []).join()} />

			<link rel="canonical" href={`${siteUrl}${location.pathname}`} />
		</Helmet>
	</>
)

export default Head

import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'

import logoSvgSrc from './logo.svg'

interface Props {
	className?: string
}

// fill={muiTheme.palette.text.primary}
const Logo: FunctionComponent<Props> = ({ className }) => (
	<img
		className={className}
		src={logoSvgSrc}
		alt=""
		css={css`
			max-width: 100%;
		`}
	/>
)
export default Logo

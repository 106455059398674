import React, { FunctionComponent, ElementType, HTMLProps } from 'react'

export interface OverridableComponentProps extends HTMLProps<HTMLDivElement> {
	component?: ElementType
}

const OverridableComponent: FunctionComponent<OverridableComponentProps> = ({ component, ...rest }: OverridableComponentProps) => {
	const Comp = component || 'div'
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Comp {...rest} />
}

export default OverridableComponent

/* eslint-disable import/prefer-default-export */

import isObject from 'lodash/isObject'
import kebabCase from 'lodash/kebabCase'
import { DeepReadonlyArray } from '../types/deep-freeze'
import { Map } from '../types'

export type Styles = Map<string | number | (string | number | null)[] | DeepReadonlyArray<number | null> | DeepReadonlyArray<string | null>>
export type ParsedStyles = Map<string | number | Map<string | number>>

function parseResponsiveStyle(
	currentStyles: ParsedStyles,
	name: string,
	styles: (string | number | null)[],
	breakpoints: string[] | DeepReadonlyArray<string>
) {
	return styles.reduce<ParsedStyles>((acc, value, index) => {
		// skip null values
		if (value === null) return acc

		const breakpoint = breakpoints[index]

		// The first value of the styles is alway applied directly without a media-query
		// because we use a mobile-first approach and regard the first value as the default style.
		if (index === 0 && (!breakpoint || breakpoint === '0px' || breakpoint === '0em' || breakpoint === '0rem')) {
			acc[name] = value
		} else {
			// Set a media-query in the format `emotion` understands
			const query = `@media only screen and (min-width: ${breakpoint})`
			if (acc[query]) {
				;(acc[query] as ParsedStyles)[name] = value
			} else {
				acc[query] = { [name]: value }
			}
		}

		return acc
	}, currentStyles)
}

/**
 * Parses a style collection and replaces Array values with the corresponding media-queries.
 * Not that the first value in an Array is regarded as the default value and will not create a media-query.
 * This is because we use a mobile-first approach when it comes to responsive styles.
 *
 * @example:
 * const styles = parseResponsiveStyles({
 *   position: 'relative',
 *   padding: ['10px', '20px', '30px'] },
 *   ['0px', '320px', '480px' ],
 * })
 *
 * // result:
 * { position: 'relative',
 *   padding: '10px',
 *   '@media only screen and (min-width: 320px)': { padding: '20px' },
 *   '@media only screen and (min-width: 480px)': { padding: '30px' } }
 */
export function parseResponsiveStyles(styles: Styles, breakpoints: string[] | DeepReadonlyArray<string>) {
	return Object.entries(styles).reduce<ParsedStyles>((acc, [name, value]) => {
		if (value.constructor !== Array) {
			acc[name] = value as string | number
		} else {
			return {
				...acc,
				...parseResponsiveStyle(acc, name, value as (string | number | null)[], breakpoints)
			}
		}

		return acc
	}, {})
}

export function stylesToCSS(value: object | string): string {
	if (typeof value === 'string') {
		return value
	}
	return Object.entries(value).reduce((acc, [key, value]) => {
		return `${acc}
			${isObject(value) ? `${key} { ${stylesToCSS(value)} }` : `${kebabCase(key)}: ${value};`}`
	}, '')
}

export const responseStylesToCSS = (styles: Styles, breakpoints: string[] | DeepReadonlyArray<string>) => {
	const parsedStyles = parseResponsiveStyles(styles, breakpoints)
	return stylesToCSS(parsedStyles)
}

export const propIn = <T>(values: any[], key: keyof T) => (props: T) => values[props[key]]

export const getProp = <T>(key: keyof T) => (props: T) => props[key]

export const ifProp = <T>(key: string, style: string) => (props: T) => (props[key] ? style : null)

import React, { FunctionComponent, ReactNode } from 'react'
import { spacing, SpacingProps } from '@material-ui/system'
import styled, { css } from 'styled-components'
import { stylesToCSS } from '~/styles/helpers'
import muiTheme from '~/styles/mui-theme'

import { LinkStyleObject } from '~/components/Link'

interface Props extends SpacingProps {
	html: string
	textAlign?: 'left' | 'center' | 'right'
}

const StyledContainer = styled.div<Props>`
	${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : '')}
	${(props) => spacing({ ...props, theme: muiTheme })}
	${(props) =>
		stylesToCSS({
			p: { ...muiTheme.typography.body1 },
			a: LinkStyleObject,
			em: { color: muiTheme.palette.primary.main, fontStyle: 'normal' },
			ol: { textAlign: 'left' },
			ul: { textAlign: 'left', display: 'inline-block' }
		})}
`

const MarkdownHTML: FunctionComponent<Props> = ({ html, ...rest }: Props) => (
	<StyledContainer {...rest} dangerouslySetInnerHTML={{ __html: html }} />
)

export default MarkdownHTML

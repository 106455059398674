// fix styled-components `css` usage for TypeScript
// @see: https://styled-components.com/docs/api#usage-with-typescript
/// <reference types="styled-components/cssprop" />

import React, { FunctionComponent } from 'react'
import { PageRendererProps, PageProps } from 'gatsby'

import Header from '../components/Header'
import Footer, { FooterDataProps } from '../components/Footer'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import Head from '../components/Head'

export type IndexLayoutDataProps = {
	pageContext: { locale: string }
	data?: {
		contentfulMeta: {
			title?: string
			keywords?: string[]
			description: {
				description: string
			}
		}
		site: {
			siteMetadata: {
				siteUrl: string
			}
		}
	}
} & FooterDataProps

type IndexLayoutProps = PageRendererProps & IndexLayoutDataProps

export const query = graphql`
	fragment ContentfulMetaData on Query {
		contentfulMeta(contentful_id: { eq: "2a21iJJHfSm8AEQOgAqyCA" }, node_locale: { eq: $locale }) {
			title
			keywords
			description {
				description
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`
const IndexLayout: FunctionComponent<IndexLayoutProps> = ({ children, data, location, pageContext }) => {
	const { locale } = pageContext
	const {
		contentfulMeta: {
			title,
			description: { description },
			keywords
		},
		site: {
			siteMetadata: { siteUrl }
		}
	} = data || { contentfulMeta: { description: {}, keywords: [] }, site: { siteMetadata: {} } }

	return (
		<LayoutRoot>
			<Head lang={locale} title={title || ''} description={description} keywords={keywords} siteUrl={siteUrl || ''} location={location} />
			<Header pathname={location.pathname} locale={locale} />
			<LayoutMain>{children}</LayoutMain>
			<Footer data={data} />
		</LayoutRoot>
	)
}

export default IndexLayout

import React, { FunctionComponent, ReactNode } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { graphql } from 'gatsby'
import { css } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { ContentfulTextBlock } from '~/types/graphql'
import MarkdownHTML from '~/components/MarkdownHTML'
import SocialButton from '~/components/SocialButton'

export interface FooterDataProps {
	data: {
		footerLeft: ContentfulTextBlock
		footerRight: ContentfulTextBlock
	}
}

export const query = graphql`
	fragment FooterData on Query {
		footerLeft: contentfulTextBlock(contentfulid: { eq: "footer-left" }, node_locale: { eq: $locale }) {
			description {
				childMarkdownRemark {
					html
				}
			}
			title
		}
		footerRight: contentfulTextBlock(contentfulid: { eq: "footer-right" }, node_locale: { eq: $locale }) {
			description {
				childMarkdownRemark {
					html
				}
			}
			title
		}
	}
`

const Footer: FunctionComponent<FooterDataProps> = ({ data: { footerLeft, footerRight } }: Props) => (
	<Box color="secondary.contrastText" bgcolor="secondary.main" py={4}>
		<Container component="footer">
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Typography variant="h3" color="inherit" gutterBottom>
						{footerLeft.title}
					</Typography>
					<MarkdownHTML html={footerLeft.description.childMarkdownRemark.html} />
					<SocialButton type="instagram" href="https://www.instagram.com/snailhouse_campervans/" target="_blank" />
					<SocialButton type="facebook" href="https://www.facebook.com/snailhousecampervans/" target="_blank" />
				</Grid>
				<Grid item xs={12} sm={6}>
					<Typography variant="h3" color="inherit" gutterBottom>
						{footerRight.title}
					</Typography>
					<MarkdownHTML html={footerRight.description.childMarkdownRemark.html} />
				</Grid>
			</Grid>
		</Container>
	</Box>
)

export default Footer

import React, { FunctionComponent, ReactNode, Fragment } from 'react'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import styled, { css } from 'styled-components'

import OverridableComponent, { OverridableComponentProps } from '../OverridableComponent'

interface ResponsiveFluid {
	mobile: FluidObject
	desktop: FluidObject
}

interface Props extends OverridableComponentProps {
	children?: ReactNode
	fluid: FluidObject | ResponsiveFluid
	objectPosition?: string
}

const StyledOverridableComponent = styled(({ maxWidthMobile, ...rest }) => <OverridableComponent {...rest} />)`
	position: relative;
	.gbi-desktop {
		display: none;
	}
	@media only screen and (min-width: ${(props) => props.maxWidthMobile + 1}px) {
		.gbi-desktop {
			display: block;
		}
		.gbi-mobile {
			display: none;
		}
	}
`

const ContentWrapper = styled.div`
	position: relative;
	z-index: 2;
`

const StyledGatsbyImage = styled(GatsbyImage)`
	position: absolute !important;
	width: 100%;
	height: 100%;
`

const GatsbyBackgroundImage: FunctionComponent<Props> = ({
	fluid,
	objectPosition,
	// desktopWidth,
	children,
	...rest
}: Props) => {
	const { mobile, desktop } = fluid as ResponsiveFluid
	const isResponsive = mobile && desktop
	let maxWidthMobile = 0

	/**
	 * The `sizes` property contains the maximum size intended for this image.
	 * Here we extract the pixel value to set an appropiate media query for when
	 * to switch to the desktop image.
	 */
	if (isResponsive) {
		const match = mobile.sizes.match(/max-width:\s(\d*)px/)
		if (!match) throw new Error('Failed to find the max width of the mobile image')
		maxWidthMobile = parseInt(match[1], 10)

		// remove original image from mobile srcSet
		if (mobile.srcSet) mobile.srcSet = mobile.srcSet.replace(/(.*?)(,\n.+\d*w)$/, '$1')
		if (mobile.srcSetWebp) mobile.srcSetWebp = mobile.srcSet.replace(/(.*?)(,\n.+\d*w)$/, '$1')
	}

	return (
		<StyledOverridableComponent maxWidthMobile={maxWidthMobile} {...rest}>
			{isResponsive ? (
				<>
					<StyledGatsbyImage
						aria-hidden="true"
						className="gbi-mobile"
						fluid={mobile}
						imgStyle={objectPosition ? { objectPosition } : undefined}
					/>
					<StyledGatsbyImage
						aria-hidden="true"
						className="gbi-desktop"
						fluid={desktop}
						imgStyle={objectPosition ? { objectPosition } : undefined}
					/>
				</>
			) : (
				<StyledGatsbyImage aria-hidden="true" fluid={fluid as FluidObject} imgStyle={objectPosition ? { objectPosition } : undefined} />
			)}

			{children ? <ContentWrapper>{children}</ContentWrapper> : ''}
		</StyledOverridableComponent>
	)
}

GatsbyBackgroundImage.defaultProps = {
	objectPosition: 'center center'
}

export type { FluidObject }

export default GatsbyBackgroundImage

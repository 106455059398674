import React, { FunctionComponent, ReactNode } from 'react'
import MaterialButton from '@material-ui/core/Button'
import styled from 'styled-components'

interface Props {
	children: ReactNode
	href?: string
}

const StyledMaterialButton = styled(MaterialButton)`
	color: #fff;
	background-color: rgba(0, 0, 0, 0.65);
	&:hover {
		background-color: rgba(0, 0, 0, 0.7);
	}
`

const Button: FunctionComponent<Props> = ({ children, href }: Props) => (
	<StyledMaterialButton variant="outlined" href={href}>
		{children}
	</StyledMaterialButton>
)

export default Button

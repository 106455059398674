import React, { FunctionComponent, ReactNode } from 'react'
import IconButton from '@material-ui/core/Button'
import styled, { css } from 'styled-components'

import theme from '~/styles/mui-theme'
import facebookSvgSrc from '../../../static/svg/illustrations/facebook.svg'
import instagramSvgSrc from '../../../static/svg/illustrations/instagram.svg'

interface Props {
	ariaLabel?: string
	type: 'facebook' | 'instagram'
	href: string
	target?: '_blank'
	rel?: 'noopener' | 'noreferrer'
}

const StyledIconButton = styled(IconButton)`
	path {
		fill: ${theme.palette.secondary.contrastText};
	}
`

const SocialButton: FunctionComponent<Props> = ({ type, href, target, ariaLabel, rel = 'noopener' }: Props) => (
	<StyledIconButton href={href} rel={rel} target={target} aria-label={ariaLabel || type}>
		<img src={{ facebook: facebookSvgSrc, instagram: instagramSvgSrc }[type]} alt={type} />
	</StyledIconButton>
)

export default SocialButton

import React, { FunctionComponent } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { HelmetProvider } from 'react-helmet-async'

import muiTheme from '~/styles/mui-theme'

interface LayoutRootProps {
	className?: string
}

const LayoutRoot: FunctionComponent<LayoutRootProps> = ({ children }) => (
	<HelmetProvider>
		<MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
	</HelmetProvider>
)

export default LayoutRoot
